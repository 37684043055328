/** @format */

import React from 'react';

const HalfPhoto = (props) => (
    <div className="section">
        <div className="container">
            <div className="half-photo-wrapper">
                <img className="photo" src={props.link} alt="Mock-Up"></img>
                <div className="subtitle">{props.subtitle}</div>
            </div>
        </div>
    </div>
);

export default HalfPhoto;
