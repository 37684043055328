/** @format */

import React, { useState } from 'react';
import ProjectBar from '../components/ProjectBar';

import Layout from '../components/layout';
import SmallPhoto from '../components/SmallPhoto';
import HalfPhoto from '../components/HalfPhoto';

import Photo from '../components/Photo';
// import Text from "../components/Text"
import Title from '../components/Title';
// import Test from '../components/test.md';
import cybercmscover from '../images/cybercmscover5.png';
import cybercms2 from '../images/cmstasks.png';
import cybercms3 from '../images/cybercms3.png';
import cybercms4 from '../images/cybercms4.png';
import cybercms5 from '../images/cybercms5.png';
import infoarch from '../images/infoarch.jpg';
import screens from '../images/screens.png';
import dashboards from '../images/dashboardIterations.png';
import finalscreens from '../images/cmsfinals.png';
import cyberSketch from '../images/sketchCyberCms.png';
import currentDash from '../images/cmsdash.png';
import bc from '../images/breachcoach.jpg';
import forensic from '../images/forensicsguy.jpg';
import ciso from '../images/ciso.jpg';
import functionality from '../images/functionality.png';
import wireframe from '../images/wireframe.png';
import feedback from '../images/feedback.png';
import chatfinal from '../images/chatfinal.png';
import dashfinal from '../images/dashfinal.png';
import documentsfinal from '../images/documentsfinal.png';
import taskfinal from '../images/taskfinal.png';
import emailfinal from '../images/emailfinal.png';
import icons from '../images/icons.png';
import { navigate } from 'gatsby-link';

import lu from '../images/liveunlimitedc.png';
import mb from '../images/mallardbayc.png';
import cms from '../images/cybercmscover.png';
import dhl from '../images/dhl1.png';

const CyberCMS = () => {
    return (
        <Layout>
            <Title
                title="Cyber CMS"
                subtitle="Streamlining Cybersecurity Incident Response"
                categories="UX/UI Design, Front-End Development, User Research"
            />
            <Photo
                link={cybercmscover}
                subtitle="Incident Response Company Facing Dashboard"
            />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h2>Overview</h2>
                        <h3 className="first">Roles</h3>
                        <p className="firstp">
                            UI/UX Design, Product Discovery, Front-End
                            Development
                        </p>
                        <h3>Problem</h3>
                        <p className="firstp">
                            Cybersecurity incident response is a critical and
                            chaotic process for affected companies due to the
                            need for multiple systems and time-intensive manual
                            processes.
                        </p>
                        <h3>Solution</h3>
                        <p className="firstp">
                            An all-in-one web platform for cybersecurity
                            incident response, enabling lawyers and forensic
                            response companies to securely communicate, complete
                            tasks, archive files, create reports, and monitor
                            the team's progress and budget.
                        </p>
                    </div>
                </div>
            </div>
            {/* <SmallPhoto link={cybercms2} subtitle="Task Screen" /> */}
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>

                        <h2>Product Discovery</h2>
                        <h3 className="first">
                            Incident Response Expert Interviews
                        </h3>
                        <p className="firstp">
                            Two team members who are the CTO and COO of a
                            Managed Service Provider believed the way
                            communication occurs during a cybersecurity incident
                            is flawed.
                        </p>
                        <p>
                            Using their knowledge as a base I interviewed 9
                            cybersecurity lawyers known as “breach coaches” to
                            understand the problem.
                        </p>
                        <h3>The Problem</h3>
                        <p className="firstp">
                            Existing platforms are either not robust enough
                            (Signal, Telegram, Gmail) or have features that do
                            not fully align to breach coaches' needs (Teams).
                            There is not a platform specifically designed for
                            breach coaches and their needs during an incident.
                        </p>
                        <p style={{ textAlign: 'center' }}>
                            <b>
                                "Teams is built to go to the moon, but we just
                                need to go around the block."
                            </b>
                        </p>
                        <p>
                            Plus, litigation requirements force users to locate
                            and store large amounts of data related to the
                            incident. For instance, if the communication occurs
                            on their own email server, the entire server will
                            need to be archived.
                        </p>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3>User Personas</h3>
                        <p className="firstp">
                            Three main user types were identified during this
                            process: Breach Coaches, Forensics Companies, and
                            the Hacked Client Company. I created user personas
                            based on the interviews conducted.
                        </p>
                    </div>
                    {/* <div className="personas-wrapper">
                        <div className="personas-container">
                            <img className="photo" src={bc}></img>
                            <div className="text-container">
                                <p className="name">Sandra Foreman</p>
                                <p className="body-title">Breach Coach</p>
                                <p className="title">
                                    Cybersecurity Lawyer (“Breach Coach”) &
                                    Ethical Hacker
                                </p>
                                <p className="text">
                                    Sandra gets assigned to the incident by the
                                    hacked company's insurance company. Her goal
                                    is to ensure that they follow all legal
                                    requirements during the breach incident
                                    remediaton process and to prepare for
                                    potential future litigation.
                                </p>
                                <p className="body-title">Needs:</p>
                                <p className="text">
                                    - An overview of everything going on within
                                    the incident
                                </p>
                                <p className="text">
                                    - To be included in internal and external
                                    communications
                                </p>
                                <p className="body-title">Pain Points:</p>
                                <p className="text">
                                    - It is difficult to track all
                                    communications
                                </p>
                                <p className="text">
                                    - She gets inundated with requests for
                                    information from the hacked companies
                                    clients
                                </p>
                                <p className="text">
                                    - All communications not covered by
                                    attorney-client privledge need to be
                                    archived, it is difficult to locate
                                    everything
                                </p>
                            </div>
                        </div>
                        <div className="personas-container">
                            <img className="photo" src={forensic}></img>
                            <div className="text-container">
                                <p className="name">Mark Jordan</p>
                                <p className="body-title">Forensics Company</p>
                                <p className="title">
                                    Managing Director of Forensics Company
                                </p>
                                <p className="text">
                                    Mark runs an IT team whose goal is to
                                    identify the source of the hack, identify
                                    what data has been lost, fix the issue, and
                                    prevent it from happening again as quickly
                                    as possible.
                                </p>
                                <p className="body-title">Needs:</p>
                                <p className="text">
                                    - To assign and track tasks to his team and
                                    the hacked company
                                </p>
                                <p className="text">- Monitor budget spend</p>
                                <p className="text">
                                    - Communicate with all parties involved in
                                    the incident
                                </p>
                                <p className="body-title">Pain Points:</p>
                                <p className="text">
                                    - Communications can be difficult to follow
                                    in current systems
                                </p>
                                <p className="text">
                                    - Hacked company does not always keep up
                                    with their tasks
                                </p>
                                <p className="text">
                                    - Realtime budget spend is difficult to get
                                </p>
                            </div>
                        </div>
                        <div className="personas-container">
                            <img className="photo" src={ciso}></img>
                            <div className="text-container">
                                <p className="name">Joe Stanley</p>
                                <p className="body-title">
                                    Hacked Client Company
                                </p>
                                <p className="title">
                                    CISO of Important Data Enterprises
                                </p>
                                <p className="text">
                                    Joe is the CISO of a company that stores
                                    large amounts of sensitive data for its
                                    clients. His goal is to ensure his team is
                                    following the orders of the forensics
                                    company. He wants his company to be back up
                                    and running as soon as possible.
                                </p>
                                <p className="body-title">Needs:</p>
                                <p className="text">
                                    - To get his team aligned and communicating
                                    quickly
                                </p>
                                <p className="text">
                                    - To track tasks assigned by the forensics
                                    company
                                </p>
                                <p className="text">
                                    - To communicate outside of his company's
                                    compromised systems
                                </p>
                                <p className="body-title">Pain Points:</p>
                                <p className="text">
                                    - It is a stressful process, his company has
                                    been the victim of a hack
                                </p>
                                <p className="text">
                                    - It is easy to get disorganized in the
                                    chaos
                                </p>
                                <p className="text">
                                    - He has to reach out to lawyers to approve
                                    all external communications
                                </p>
                            </div>
                        </div>
                        {/* </div>
                    </div> */}
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <hr></hr>
                        <h2>Design Process</h2>
                        <h3 className="first">Information Architecture</h3>
                        <p className="firstp">
                            Working alongside the development team we took the
                            feedback and organized it into the pages and
                            features of the application.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={infoarch} subtitle="" />
            {/* <HalfPhoto link={cyberSketch} subtitle="" /> */}
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3 className="first">Dashboard Design</h3>
                        <p className="firstp">
                            Based on information gathered from the expert
                            interviews, I knew breach coaches wanted to see a
                            dashboard with information about the incident, but
                            finding the right subset of information for the
                            dashboard would be the challenge.
                        </p>
                        {/* <p>
                            <strong>Important Information:</strong>

                            <ul>
                                <li>Budget Tracking</li>
                                <li>Customer Emails</li>
                                <li>Overall Progress</li>
                                <li>Recently Uploaded Files</li>
                                <li>Incident Updates</li>
                                <li>High Priority Tasks</li>
                            </ul>
                        </p> */}
                    </div>
                </div>
            </div>
            <SmallPhoto link={wireframe} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3>Wireframe Research</h3>
                        <p className="firstp">
                            I first presented each user type with the wireframe.
                            It had a very bare bones design, but had titles for
                            each initial dashboard widget: Tasks, Emails,
                            Updates, and Files. These original widgets were
                            based on the needs of each user persona.
                        </p>
                        <p>
                            I gathered feedback and asked questions to dive
                            deeper into which types of information are most
                            important to have access to quickly during an
                            incident.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={feedback} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3>Incorporating Feedback</h3>
                        <p className="firstp">
                            Their feedback informed me that it's important to
                            know task completion status, uncompleted tasks near
                            the due date, and budget spending.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={functionality} subtitle="" />
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3>Improving Functionality</h3>
                        <p className="firstp">
                            Then their feedback was used to expand on the simple
                            widget designs to create the detailed dashboard
                            widgets and task overview section.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={currentDash} subtitle="" />
            {/* <SmallPhoto link={cybercms3} subtitle="Task Screen" /> */}
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h3>Current Dashboard</h3>
                        <p className="firstp">
                            Forensic Company users wanted the ability to know
                            more information about each task group from the
                            dashboard.
                        </p>
                        <p>
                            To accomplish this I:
                            <ul>
                                <li>
                                    Added progress bars to show task completion
                                    percentages
                                </li>
                                <li>
                                    Added icons that show how many tasks are
                                    overdue, due soon, and in progress
                                </li>
                            </ul>
                        </p>
                        <p>
                            Future Changes:
                            <ul>
                                <li>Changes based on user feedback</li>
                                <li>
                                    Dashboard views designed for each user type
                                    - Breach Coaches, Forensic Companies, and
                                    the Hacked Client Company
                                </li>
                            </ul>
                        </p>
                        <hr></hr>
                    </div>
                </div>
            </div>
            <div className="section">
                <div className="container">
                    <div className="small-photo-wrapper">
                        <h2 className="first">
                            Current Design and Development
                        </h2>
                        {/* <h3>5 Sections</h3> */}
                        <p className="firstp">
                            The next few screens will walk you through the
                            application in its current state.
                        </p>
                        <p>
                            I also developed the front-end to match the
                            prototype using HTML, CSS, and JavaScript
                            (Knockout.JS).
                        </p>
                        <h3>Icons</h3>
                        <p className="firstp">
                            I created a set of custom icons for the project.
                        </p>
                    </div>
                </div>
            </div>
            <SmallPhoto link={icons} subtitle="Custom Icons" />
            <br></br>
            <br></br>
            <SmallPhoto link={dashfinal} subtitle="Incident Dashboard" />
            <br></br>
            <br></br>
            <SmallPhoto link={taskfinal} subtitle="Manage and Track Tasks" />
            <br></br>
            <br></br>
            <SmallPhoto
                link={emailfinal}
                subtitle="Respond and Archive Emails"
            />
            <br></br>
            <br></br>
            <SmallPhoto
                link={chatfinal}
                subtitle="Chat and Meet With the Team"
            />
            <br></br>
            <br></br>
            <SmallPhoto
                link={documentsfinal}
                subtitle="Store Important Documents"
            />
            <br></br>
            <br></br>
            {/* <Photo link={finalscreens} subtitle="" /> */}

            {/* <ProjectBar /> */}

            <div className="section" id="content-desktop">
                <div className="container">
                    <div className="project-bar-container">
                        <div
                            className="project-container"
                            onClick={() => navigate(`/liveunlimited`)}>
                            <img className="project-photo" src={lu}></img>
                            <div className="project-name">Live Unlimited</div>
                        </div>
                        <div
                            className="project-container-active"
                            onClick={() => navigate(`/cybercms/`)}>
                            <img className="project-photo" src={cms}></img>
                            <div className="project-name">Cyber CMS</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/mallardbay/`)}>
                            <img className="project-photo" src={mb}></img>
                            <div className="project-name">Mallard Bay</div>
                        </div>
                        <div
                            className="project-container"
                            onClick={() => navigate(`/otherwork/`)}>
                            <img className="project-photo" src={dhl}></img>
                            <div className="project-name">Other Work</div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};
export default CyberCMS;
